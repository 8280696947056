









import { defineComponent, onMounted } from "@vue/composition-api"
import I18nFormattedMessage from "@/components/i18n/I18nFormattedMessage.vue"
import { teamsContextContainer } from "@/containers/TeamsContextContainer"

export default defineComponent({
  components: {
    I18nFormattedMessage,
  },
  setup() {
    const {
      notifyFailure,
      notifySuccessnotifySuccess,
    } = teamsContextContainer.useContainer()
    onMounted(() => {
      let params = getHashParameters()
      const accessToken = params["access_token"] || ""

      if (accessToken) {
        notifySuccessnotifySuccess(JSON.stringify(params))
      } else {
        params = getQueryParameters()
        const code = params["code"] || ""
        if (code) {
          notifySuccessnotifySuccess(JSON.stringify(params))
        } else {
          notifyFailure(params["error_description"] || "unknown")
        }
      }
    })
  },
})

function getHashParameters(): Record<string, string> {
  return window.location.hash
    .substr(1)
    .split("&")
    .reduce((params, param) => {
      const [key, value] = param.split("=")
      return { ...params, [key]: decodeURIComponent(value) }
    }, {} as Record<string, string>)
}

function getQueryParameters(): Record<string, string> {
  return window.location.search
    .substr(1)
    .split("&")
    .reduce((params, param) => {
      const [key, value] = param.split("=")
      return { ...params, [key]: decodeURIComponent(value) }
    }, {} as Record<string, string>)
}
